import React, { useState } from "react";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";
import "../App.css";

function App() {
  const navigate = useNavigate();
  const [waitlistView, setWaitlistView] = useState(false);

  function showWaitlist() {
    setWaitlistView(true);
    posthog.capture("Show Waitlist");
  }

  function navigateToBlog() {
    navigate("/blog");
  }

  return (
    <div className="bg-[#fffbf6]">
      {waitlistView ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <iframe
            src="https://tally.so/embed/w7J6p2?&transparentBackground=1&dynamicHeight=1"
            className="w-full h-[529px]"
            title="Pumpkin Waitlist"
          ></iframe>
        </div>
      ) : (
        <div className="bg-[#fffbf6] flex flex-row justify-between">
          <div className="w-full lg:w-1/2 h-screen gap-10 flex flex-col items-center justify-center">
            <div className="flex flex-row justify-start items-center">
              {/* <img src="pumpkin.svg" alt="Pumpkin Logo" className="w-14 h-14" /> */}
              <h1 className="ml-3 pb-2 text-4xl sm:text-7xl font-bold leading-7 text-[#344249] ">
                🎃 Pumpkin
              </h1>
            </div>
            <div>
              <p className="-mt-4 -mb-4 text-md sm:text-xl text-[#313f49] font-normal text-center">
                A web browser built for human and AI collaboration
              </p>
            </div>
            <div>
              <button
                className="text-sm sm:text-lg bg-[#202d36] rounded-3xl text-[#fffbf6] font-normal px-8 py-2
            relative overflow-hidden group"
                onClick={() => showWaitlist()}
              >
                <span className="relative z-10 group-hover:text-black">
                  Join the waitlist
                </span>
                <div className="absolute inset-0 bg-[#ffb196] transform -translate-x-full transition-transform duration-300 ease-out group-hover:translate-x-0"></div>
              </button>
            </div>
            <div
              className="absolute bottom-8 hover:cursor-pointer hover:scale-105 transition-transform duration-300"
              onClick={navigateToBlog}
            >
              {/* <p>Blog</p> */}
            </div>
          </div>
          <div className="hidden lg:block w-1/2">
            <img src="./banner.webp" className="w-full h-full"></img>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
