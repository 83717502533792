import React from "react";
import { Routes, Route } from "react-router-dom";
import Landing from "./Pages/Landing";
import Blog from "./Pages/Blog";
import BlogPost from "./Pages/BlogPost";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:id" element={<BlogPost />} />
    </Routes>
  );
}

export default App;
