import React from "react";
import { useNavigate } from "react-router-dom";
import blogs from "./blog.json";

function Blog() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="bg-[#fffbf6] min-h-screen">
        <div className="flex flex-row justify-start items-center p-10">
          <img
            src="pumpkin.svg"
            className="w-10 h-10 hover:cursor-pointer hover:scale-105 transition-all duration-300"
            onClick={() => navigate("/")}
          ></img>
        </div>
        <div className="flex flex-col justify-center items-center">
          <h1 className="mt-20 text-center text-6xl font-bold">Blog</h1>
        </div>
        <div className="">
          <div className="mt-10 flex flex-col justify-center items-center w-screen">
            {Object.entries(blogs).map(([id, blog]) => (
              <div
                onClick={() =>
                  navigate(
                    `/blog/${blog.title.toLowerCase().replace(/\s+/g, "-")}`
                  )
                }
                className="flex flex-row justify-between items-center mt-10 hover:cursor-pointer hover:-translate-y-1 transition-all duration-300"
              >
                <div className="flex flex-col justify-start text-left w-1/2">
                  <p className="text-2xl font-semibold">{blog.title}</p>
                  <p className="text-lg font-normal">
                    {blog.author === "Sam Crombie" ? (
                      <a
                        href="https://linkedin.com/in/samcrombie"
                        className="hover:underline"
                      >
                        {blog.author}
                      </a>
                    ) : (
                      blog.author
                    )}{" "}
                    | {blog.date}
                  </p>
                  <p className="mt-1 text-md font-light leading-6">
                    {blog.snippet}
                  </p>
                </div>
                <div className="">
                  <img src={blog.image} className="h-max w-32" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
