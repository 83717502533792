import React from "react";
import { useNavigate } from "react-router-dom";

function BlogPost() {
  const navigate = useNavigate();

  // todo:  get path, go to blog.json, load, get full article from json
  return (
    // todo: could do basic breadcrumb
    <div>
      <div className="bg-[#fffbf6] min-h-screen">
        <div className="flex flex-row justify-start items-center p-10">
          <img
            src="pumpkin.svg"
            className="w-10 h-10 hover:cursor-pointer hover:scale-105 transition-all duration-300"
            onClick={() => navigate("/blog")}
          ></img>
        </div>
        <div className="px-20">
          <img src="./hello_world.webp" className="w-40 h-40"></img>
        </div>
      </div>
    </div>
  );
}

export default BlogPost;
